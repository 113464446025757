<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="content-col-desktop">
  <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-sm class="header-desktop header-desktop-r">
    <div fxFlex="0 0 calc(50% - 125px)"></div>
    <div class="cartusLogoSection" fxFlex="0 0 250px">
      <span class="cartusLogo"></span>
    </div>
    <div fxFlex="0 0 calc(50% - 125px)" fxLayout="row" fxLayoutAlign="end center">
      <app-user-context [displayContextOption]="displayUserContextOption" [(selectedContext)]="selectedUserContext"
        [userContexts]="userContexts" (contextChange)="contextChangeHandler($event)"
        (loggedOut)="logoutHandler($event)"></app-user-context>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.lt-md class="header-mobile">
    <div fxFlex="0 0 calc(50% - 125px)"></div>
    <div class="cartusLogoSection" fxFlex="0 0 250px">
      <span class="cartusLogo"></span>
    </div>
    <div fxFlex="0 0 calc(50% - 125px)" fxLayout="row" fxLayoutAlign="end center">
      <app-user-context [displayContextOption]="displayUserContextOption" [(selectedContext)]="selectedUserContext"
        [userContexts]="userContexts" (contextChange)="contextChangeHandler($event)"
        (loggedOut)="logoutHandler($event)"></app-user-context>
    </div>
  </div>
  <div class="sb-content">
    <!-- User Context Selector -->
    <div class="context-selector-outer" *ngIf="displayUserContextSelection">
      <div class="context-selector-inner">
        <h1><span class="material-icons">account_circle</span>Select Account</h1>
        <div class="table-scroll mat-elevation-z8">
          <table matSort mat-table [dataSource]="userDataSource" (matSortChange)="sortUserContexts($event)">
            <caption class="cdk-visually-hidden">Select Account</caption>
            <!-- Application Column -->
            <ng-container matColumnDef="application" sticky>
              <th mat-header-cell *matHeaderCellDef mat-sort-header="application"> Application </th>
              <td mat-cell *matCellDef="let element">
                <div>
                  <a fxFlex.layout="stretch baseline" (click)="selectUserContext(element, true)">
                    <span [innerHTML]="element.application"></span>
                  </a>
                </div>
              </td>
            </ng-container>
            <!-- Company Column -->
            <ng-container matColumnDef="company" sticky>
              <th mat-header-cell *matHeaderCellDef mat-sort-header="clientLegalName"> Company </th>
              <td mat-cell *matCellDef="let element">
                <div>
                  <a fxFlex.layout="stretch baseline" (click)="selectUserContext(element, true)">
                    <span [innerHTML]="element.clientLegalName"></span>
                  </a>
                  <a (click)="selectUserContext(element, true)">
                    <span>Client#: </span><span [innerHTML]="element.clientNo"></span>
                  </a>
                </div>
              </td>
            </ng-container>
            <!-- Company Address Column -->
            <ng-container matColumnDef="companyAddr">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="clientAddrLine1"> Company Address </th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.clientAddrLine1">
                  <span [innerHTML]="element.clientAddrLine1 + ', '"></span>
                  <span [innerHTML]="element.clientAddrLine2 ? element.clientAddrLine2 + ', ' : ''"></span>
                  <br />
                  <span [innerHTML]="element.clientCityName ? element.clientCityName + ', ' : ''"></span>
                  <span [innerHTML]="element.clientStProvName ? element.clientStProvName + ', ' : ''"></span>
                  <br />
                  <span [innerHTML]="element.clientCountryName ? element.clientCountryName + ', ' : ''"></span>
                  <span [innerHTML]="element.clientPostalCode ? element.clientPostalCode : ''"></span>
                </div>
                <div *ngIf="!element.clientAddrLine1">
                  <span>-</span>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="userDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: userDisplayedColumns;"
              (keyup.enter)="selectUserContext(row, true)" (click)="selectUserContext(row, true)">
              <button></button>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- Supplier Context Selector -->
    <div class="context-selector-outer" *ngIf="displaySuppContextSelection">
      <div class="context-selector-inner">
        <h1><span class="material-icons">account_circle</span>Select Account</h1>
        <div class="table-scroll mat-elevation-z8">
          <table matSort mat-table [dataSource]="suppDataSource" (matSortChange)="sortSuppContexts($event)">
            <caption class="cdk-visually-hidden">Select Account</caption>

            <!-- Application Column -->
            <ng-container matColumnDef="application" sticky>
              <th mat-header-cell *matHeaderCellDef mat-sort-header="application"> Application </th>
              <td mat-cell *matCellDef="let element">
                <div>
                  <a fxFlex.layout="stretch baseline" (click)="selectSuppContext(element)">
                    <span>ATLAS</span>
                  </a>
                </div>
              </td>
            </ng-container>
            <!-- Supplier Column -->
            <ng-container matColumnDef="supplier" sticky>
              <th mat-header-cell *matHeaderCellDef mat-sort-header="entityName"> Company </th>
              <td mat-cell *matCellDef="let element">
                <div>
                  <a fxFlex.layout="stretch baseline" (click)="selectSuppContext(element)">
                    <span [innerHTML]="element.entityName +'. #'+ element.supplierEdgeId"></span>
                  </a>
                </div>
              </td>
            </ng-container>
            <!-- Supplier Address Column -->
            <ng-container matColumnDef="supplierAddr">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="addrLine1"> Company Address </th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.addrLine1">
                  <span [innerHTML]="element.addrLine1 + ', '"></span>
                  <span [innerHTML]="element.addrLine2 ? element.addrLine2 + ', ' : ''"></span>
                  <br />
                  <span [innerHTML]="element.city ? element.city + ', ' : ''"></span>
                  <span [innerHTML]="element.state ? element.state + ', ' : ''"></span>
                  <br />
                  <span [innerHTML]="element.country ? element.country + ', ' : ''"></span>
                  <span [innerHTML]="element.postalCode ? element.postalCode : ''"></span>
                </div>
                <div *ngIf="!element.addrLine1">
                  <span>-</span>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="suppDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: suppDisplayedColumns;" (keyup.enter)="selectSuppContext(row)"
              (click)="selectSuppContext(row)"></tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="springboardError" class="error-outer">
      <div class="error-inner">
        <h1><span class="material-icons">info</span>{{errorHeader}}</h1>
        <div class="mat-elevation-z8">
          <p class="stext">Cartus' Help Desk is here to help you with all of your technical needs from Sunday 8:00 p.m.
            through Friday 8:00 p.m. Eastern U.S. time.</p>
          <p class="stext">For assistance with other questions or issues, please contact your Cartus Representative.</p>
          <p class="stext">
            <span class="sub-heading">Contact the Cartus Help Desk team</span><br>
            <span class="sub-heading">By Phone: </span>1.800.316.8070 (within the U.S.) or +1.203.205.1726<br>
            <span class="sub-heading">By Email: </span><a
              href="mailto:helpdesk-cartus@cartus.com">helpdesk-cartus@cartus.com</a>
          </p>
          <p class="stext">Please include your name, phone number and a description of your issue.</p>
        </div>
      </div>
    </div>
    <div *ngIf="displayLoggedOut" class="info-outer">
      <div class="info-inner">
        <h1><span class="material-icons">info</span>{{logOutMessage}}</h1>
      </div>
    </div>
    <div *ngIf="referrerError" class="info-outer referrerError">
      <div class="info-inner">
        <h1 class="mat-error"><span class="material-icons">info</span>{{referrerMessage$ | async}}</h1>
      </div>
    </div>
    <div class="sb-outer" *ngIf="!displayUserContextSelection && displayAppSelection && applications">
      <div class="sb-inner">
        <h1><span class="material-icons">apps</span>Applications</h1>
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="sb-container" fxLayout="row wrap" fxLayoutAlign="start center">
            <app-springboard-app *ngFor="let application of applications" [application]="application"
              [loginAppFlag]="loginAppFlag" fxFlex="0 0 calc(33.33%)" fxFlex.lt-lg="0 0 calc(50%)"
              fxFlex.lt-md="0 0 calc(100%)" (appSelected)="appSelectedHandler($event)">
            </app-springboard-app>
          </div>
        </div>
      </div>
    </div>
    <div class="sb-bottom"></div>
    <div class="sb-footer">
      <app-footer></app-footer>
    </div>
  </div>
</div>